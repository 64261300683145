import React, { useState } from "react";
import styled from "styled-components";
import { BaseInput, StyledSelect, CheckboxInput } from "../shared";

const ViewWrapper = styled.div`
  width: 100%;
`;

const MoneyGameWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 6ch auto 1ch 5ch;
  grid-gap: 0.5rem;
  background-color: gray;
  padding: 1rem;
  box-sizing: border-box;
  margin-bottom: 1rem;
`;

const EighteenWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`;

const NineHoleWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2ch auto 6ch 6ch;
  grid-gap: 0.5rem;
  background: gray;
  padding: 1rem;
  box-sizing: border-box;
`;

const GameInput = styled(BaseInput)`
  min-width: unset;
`;

const HoleSelect = styled(StyledSelect)`
  min-width: unset;
`;

const ScoreOptions = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
  { value: 11, label: 11 },
  { value: 12, label: 12 },
  { value: 13, label: 13 },
  { value: 14, label: 14 },
  { value: 15, label: 15 }
];

export const MemberScorecard = () => {
  const [moneyGameMap, setMoneyGameMap] = useState({
    game1: {},
    game2: {}
  });

  const [scoreMap, setScoreMap] = useState({
    1: { value: 0 },
    2: { value: 0 },
    3: { value: 0 },
    4: { value: 0 },
    5: { value: 0 },
    6: { value: 0 },
    7: { value: 0 },
    8: { value: 0 },
    9: { value: 0 },
    10: { value: 0 },
    11: { value: 0 },
    12: { value: 0 },
    13: { value: 0 },
    14: { value: 0 },
    15: { value: 0 },
    16: { value: 0 },
    17: { value: 0 },
    18: { value: 0 }
  });

  const [game1Name, setGame1Name] = useState("");
  const [game2Name, setGame2Name] = useState("");

  const [game1Value, setGame1Value] = useState("");
  const [game2Value, setGame2Value] = useState("");

  return (
    <ViewWrapper>
      <MoneyGameWrapper>
        <label>Game 1</label>
        <GameInput
          placeholder="Game 1 Name"
          value={game1Name}
          onChange={(e) => setGame1Name(e.target.value)}
        />
        <label>$</label>
        <GameInput
          placeholder="0"
          value={game1Value}
          onChange={(e) => {
            setGame1Value(e.target.value);
          }}
        />
        <label>Game 2</label>
        <GameInput
          placeholder="Game 2 Name"
          value={game2Name}
          onChange={(e) => setGame2Name(e.target.value)}
        />
        <label>$</label>
        <GameInput placeholder="0" value={game2Value} onChange={(e) => setGame2Value(e.target.value)} />
      </MoneyGameWrapper>
      {/* <EighteenWrapper> */}
      <NineHoleWrapper>
        <span style={{ gridColumn: "span 4" }}>Player Name</span>
        <span>#</span>
        <span>Score</span>
        <span style={{ textAlign: "center" }}>{game1Name.length > 0 ? game1Name : "Game 1"}</span>
        <span style={{ textAlign: "center" }}>{game2Name.length > 0 ? game2Name : "Game 2"}</span>
        <span>1</span>
        <HoleSelect
          placeholder="0"
          value={scoreMap[1]}
          onChange={(value) => setScoreMap({ ...scoreMap, [1]: value })}
          options={ScoreOptions}
        />
        <CheckboxInput
          onClick={() =>
            setMoneyGameMap({
              ...moneyGameMap,
              game1: { ...moneyGameMap.game1, 1: !!!moneyGameMap.game1[1] }
            })
          }
          checked={!!moneyGameMap.game1[1]}
        />
        <CheckboxInput
          onClick={() =>
            setMoneyGameMap({
              ...moneyGameMap,
              game2: { ...moneyGameMap.game2, 1: !!!moneyGameMap.game2[1] }
            })
          }
          checked={!!moneyGameMap.game2[1]}
        />
        <span>2</span>
        <HoleSelect
          placeholder="0"
          value={scoreMap[2]}
          onChange={(value) => setScoreMap({ ...scoreMap, [2]: value })}
          options={ScoreOptions}
        />
        <CheckboxInput
          onClick={() =>
            setMoneyGameMap({
              ...moneyGameMap,
              game1: { ...moneyGameMap.game1, 2: !!!moneyGameMap.game1[2] }
            })
          }
          checked={!!moneyGameMap.game1[2]}
        />
        <CheckboxInput
          onClick={() =>
            setMoneyGameMap({
              ...moneyGameMap,
              game2: { ...moneyGameMap.game2, 2: !!!moneyGameMap.game2[2] }
            })
          }
          checked={!!moneyGameMap.game2[2]}
        />
        <span>3</span>
        <HoleSelect
          placeholder="0"
          value={scoreMap[3]}
          onChange={(value) => setScoreMap({ ...scoreMap, [3]: value })}
          options={ScoreOptions}
        />
        <CheckboxInput
          onClick={() =>
            setMoneyGameMap({
              ...moneyGameMap,
              game1: { ...moneyGameMap.game1, 3: !!!moneyGameMap.game1[3] }
            })
          }
          checked={!!moneyGameMap.game1[3]}
        />
        <CheckboxInput
          onClick={() =>
            setMoneyGameMap({
              ...moneyGameMap,
              game2: { ...moneyGameMap.game2, 3: !!!moneyGameMap.game2[3] }
            })
          }
          checked={!!moneyGameMap.game2[3]}
        />
        <span>4</span>
        <HoleSelect
          placeholder="0"
          value={scoreMap[4]}
          onChange={(value) => setScoreMap({ ...scoreMap, [4]: value })}
          options={ScoreOptions}
        />
        <CheckboxInput
          onClick={() =>
            setMoneyGameMap({
              ...moneyGameMap,
              game1: { ...moneyGameMap.game1, 4: !!!moneyGameMap.game1[4] }
            })
          }
          checked={!!moneyGameMap.game1[4]}
        />
        <CheckboxInput
          onClick={() =>
            setMoneyGameMap({
              ...moneyGameMap,
              game2: { ...moneyGameMap.game2, 4: !!!moneyGameMap.game2[4] }
            })
          }
          checked={!!moneyGameMap.game2[4]}
        />
        <span>5</span>
        <HoleSelect
          placeholder="0"
          value={scoreMap[5]}
          onChange={(value) => setScoreMap({ ...scoreMap, [5]: value })}
          options={ScoreOptions}
        />
        <CheckboxInput
          onClick={() =>
            setMoneyGameMap({
              ...moneyGameMap,
              game1: { ...moneyGameMap.game1, 5: !!!moneyGameMap.game1[5] }
            })
          }
          checked={!!moneyGameMap.game1[5]}
        />
        <CheckboxInput
          onClick={() =>
            setMoneyGameMap({
              ...moneyGameMap,
              game2: { ...moneyGameMap.game2, 5: !!!moneyGameMap.game2[5] }
            })
          }
          checked={!!moneyGameMap.game2[5]}
        />
        <span>6</span>
        <HoleSelect
          placeholder="0"
          value={scoreMap[6]}
          onChange={(value) => setScoreMap({ ...scoreMap, [6]: value })}
          options={ScoreOptions}
        />
        <CheckboxInput
          onClick={() =>
            setMoneyGameMap({
              ...moneyGameMap,
              game1: { ...moneyGameMap.game1, 6: !!!moneyGameMap.game1[6] }
            })
          }
          checked={!!moneyGameMap.game1[6]}
        />
        <CheckboxInput
          onClick={() =>
            setMoneyGameMap({
              ...moneyGameMap,
              game2: { ...moneyGameMap.game2, 6: !!!moneyGameMap.game2[6] }
            })
          }
          checked={!!moneyGameMap.game2[6]}
        />
        <span>7</span>
        <HoleSelect
          placeholder="0"
          value={scoreMap[7]}
          onChange={(value) => setScoreMap({ ...scoreMap, [7]: value })}
          options={ScoreOptions}
        />
        <CheckboxInput
          onClick={() =>
            setMoneyGameMap({
              ...moneyGameMap,
              game1: { ...moneyGameMap.game1, 7: !!!moneyGameMap.game1[7] }
            })
          }
          checked={!!moneyGameMap.game1[7]}
        />
        <CheckboxInput
          onClick={() =>
            setMoneyGameMap({
              ...moneyGameMap,
              game2: { ...moneyGameMap.game2, 7: !!!moneyGameMap.game2[7] }
            })
          }
          checked={!!moneyGameMap.game2[7]}
        />
        <span>8</span>
        <HoleSelect
          placeholder="0"
          value={scoreMap[8]}
          onChange={(value) => setScoreMap({ ...scoreMap, [8]: value })}
          options={ScoreOptions}
        />
        <CheckboxInput
          onClick={() =>
            setMoneyGameMap({
              ...moneyGameMap,
              game1: { ...moneyGameMap.game1, 8: !!!moneyGameMap.game1[8] }
            })
          }
          checked={!!moneyGameMap.game1[8]}
        />
        <CheckboxInput
          onClick={() =>
            setMoneyGameMap({
              ...moneyGameMap,
              game2: { ...moneyGameMap.game2, 8: !!!moneyGameMap.game2[8] }
            })
          }
          checked={!!moneyGameMap.game2[8]}
        />
        <span>9</span>
        <HoleSelect
          placeholder="0"
          value={scoreMap[9]}
          onChange={(value) => setScoreMap({ ...scoreMap, [9]: value })}
          options={ScoreOptions}
        />
        <CheckboxInput
          onClick={() =>
            setMoneyGameMap({
              ...moneyGameMap,
              game1: { ...moneyGameMap.game1, 9: !!!moneyGameMap.game1[9] }
            })
          }
          checked={!!moneyGameMap.game1[9]}
        />
        <CheckboxInput
          onClick={() =>
            setMoneyGameMap({
              ...moneyGameMap,
              game2: { ...moneyGameMap.game2, 9: !!!moneyGameMap.game2[9] }
            })
          }
          checked={!!moneyGameMap.game2[9]}
        />
        <span></span>
        <span>
          Total{" "}
          {Object.values(scoreMap).reduce((acc, curr) => {
            return acc + curr.value;
          }, 0)}
        </span>
        <span style={{ textAlign: "center" }}>
          {Object.values(moneyGameMap.game1).reduce((acc, curr) => {
            if (curr) {
              acc += parseInt(game1Value);
            }
            return acc;
          }, 0)}
        </span>
        <span style={{ textAlign: "center" }}>
          {Object.values(moneyGameMap.game2).reduce((acc, curr) => {
            if (curr) {
              acc += parseInt(game2Value);
            }
            return acc;
          }, 0)}
        </span>
      </NineHoleWrapper>
      {/* <NineHoleWrapper>
        <span>#</span>
        <span>Score</span>
        <span>Total</span>
        <span>10</span>
        <HoleSelect placeholder="0" />
        <span>0</span>
        <span>11</span>
        <HoleSelect placeholder="0" />
        <span>0</span>
        <span>12</span>
        <HoleSelect placeholder="0" />
        <span>0</span>
        <span>13</span>
        <HoleSelect placeholder="0" />
        <span>0</span>
        <span>14</span>
        <HoleSelect placeholder="0" />
        <span>0</span>
        <span>15</span>
        <HoleSelect placeholder="0" />
        <span>0</span>
        <span>16</span>
        <HoleSelect placeholder="0" />
        <span>0</span>
        <span>17</span>
        <HoleSelect placeholder="0" />
        <span>0</span>
        <span>18</span>
        <HoleSelect placeholder="0" />
        <span>0</span>
      </NineHoleWrapper> */}
      {/* </EighteenWrapper> */}
    </ViewWrapper>
  );
};
