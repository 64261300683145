import React, { useState, useEffect, useCallback } from "react";
import { Route, Switch, useLocation, useRouteMatch, Link } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";
import { colors } from "../../colors";
import PullToRefresh from "react-simple-pull-to-refresh";
import {
  StyledLink,
  ModalWrapper,
  Modal,
  StyledSelect,
  ConfirmText,
  ButtonLinkStyles,
  Button,
  LoginSubmitBtn
} from "../shared";
import { HashLink } from "react-router-hash-link";
import { AlertWidget, MemberHome, MemberProfile, useVenueNavContext, useSearchContext } from "./";
import { MemberVenue } from "./venue";
import { MemberOffer } from "./offer";
import { VenueSearchMenu } from "./";
import bubblesBgPng from "../../assets/grass_medium_texture.jpg";
import { MdHome, MdPerson, MdSearch, MdClose, MdLocationPin } from "react-icons/md";
import { FaGolfBall } from "react-icons/fa";
import { VscBell } from "react-icons/vsc";
import brewSaverLogo from "../../assets/GS_Logo_M.png";
import appleShareIcon from "../../assets/apple-share-icon.png";
import installAndroidImg from "../../assets/install_android.png";
import iosHomeScreen from "../../assets/ios_homescreen.png";
import { getCookie, handleLogout } from "../../utility";
import { Copyright } from "../copyright";
import { Loader } from "../loaders";
import { Map } from "../map";
import { MemberScorecard } from "./scorecard";

const GET_SETTINGS = gql`
  query {
    settings {
      edges {
        node {
          settingsId
          group
          data
        }
      }
    }
  }
`;

const GET_USER_FROM_COOKIE = gql`
  query ($cookie: String!) {
    userFromCookie(cookie: $cookie) {
      address
      city
      email
      firstName
      lastName
      notes
      phone
      state
      userType
      userStatus
      zipCode
    }
  }
`;

const GET_VENUE_DROPDOWN = gql`
  query {
    allActiveVenues {
      venueId
      name
      address
      city
      state
      zipCode
    }
  }
`;

const HomeButton = styled(HashLink)`
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: ${colors.navLinkBg};
  color: ${colors.navLinkFont};
  margin: 5px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.defaultBg};
  background-image: url(${bubblesBgPng});
  background-size: cover;
  height: 100vh;
  width: 100%;
  overflow: auto;
`;

const boxShadow = "5px 5px 10px rgba(0,0,0, .8)";

const Nav = styled.nav`
  margin: 20px;
  padding: 5px 15px;
  border-radius: 10px;
  background-color: ${colors.navBg};
  box-shadow: ${boxShadow};
  width: 80%;
  display: flex;
  justify-content: center;
  min-height: 40px;
`;

const height = "70px";
const width = "100%";

const HeaderPlaceholder = styled.div`
  padding: 35px 0;
  width: ${width};
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  height: ${height};
  width: ${width};
  z-index: 5;
  background-color: ${colors.secondaryTransparent};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavMenu = styled.menu`
  margin: 0;
  padding: 0;
  width: ${({ width }) => width};
  transition: all 1s;
  height: calc(100vh - ${height});
  background-color: ${colors.secondaryTransparent};
  position: absolute;
  right: 0;
  z-index: 10;
  list-style: none;
  overflow-x: hidden;
  max-width: 100%;
  top: ${height};
  border-top: ${({ width }) => (width !== "0" ? `1px solid ${colors.secondaryOffset}` : "none")};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const MapWrapper = styled.section`
  margin: 0;
  padding: 0;
  width: 100%;
  height: calc(100vh - ${height});
  background-color: ${colors.secondaryTransparent};
  position: absolute;
  right: 0;
  z-index: 10;
  list-style: none;
  overflow-x: hidden;
  top: ${height};
  border-top: 1px solid ${colors.secondaryOffset};
  justify-content: center;
  align-items: flex-start;
  display: flex;
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
`;

const NavMenuVertical = styled.menu`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: ${({ height }) => height};
  width: 100%;
  transition: all 1s;
  background-color: ${colors.secondaryTransparent};
  position: absolute;
  right: 0;
  z-index: 10;
  list-style: none;
  overflow-x: hidden;
  max-width: 100%;
  top: ${height};
  border-top: ${({ height }) => (height !== "0" ? `1px solid ${colors.secondaryOffset}` : "none")};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Home = styled(MdHome)`
  color: ${colors.defaultBg};
  background-color: ${({ isActive }) => (isActive ? `${colors.headerIcon}` : "unset")};
  padding: 5px 10px;
  max-width: 100%;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-right: 1px solid ${colors.secondaryOffset};
`;

const VenueIcon = styled(FaGolfBall)`
  color: ${colors.defaultBg};
  padding: 5px 10px;
  background-color: ${({ isActive }) => (isActive ? `${colors.headerIcon}` : "unset")};
  max-width: 100%;
  border-right: 1px solid ${colors.secondaryOffset};
`;

const Search = styled(MdSearch)`
  color: ${colors.defaultBg};
  padding: 5px 10px;
  background-color: ${({ isActive }) => (isActive ? `${colors.headerIcon}` : "unset")};
  max-width: 100%;
  border-right: 1px solid ${colors.secondaryOffset};
`;

const MapIcon = styled(MdLocationPin)`
  color: ${colors.defaultBg};
  padding: 5px 10px;
  background-color: ${({ isActive }) => (isActive ? `${colors.headerIcon}` : "unset")};
  max-width: 100%;
  border-right: 1px solid ${colors.secondaryOffset};
`;

const Alert = styled(VscBell)`
  color: ${colors.defaultBg};
  padding: 5px 10px;
  background-color: ${({ isActive }) => (isActive ? `${colors.headerIcon}` : "unset")};
  max-width: 100%;
  border-right: 1px solid ${colors.secondaryOffset};
`;

const Profile = styled(MdPerson)`
  color: ${colors.defaultBg};
  padding: 5px 10px;
  background-color: ${({ isActive }) => (isActive ? `${colors.headerIcon}` : "unset")};
  max-width: 100%;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
`;

const MenuCloser = styled(MdClose)`
  padding: 10px;
  color: ${colors.defaultBg};
  max-width: 100%;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  border-radius: 10px;
  background-color: ${colors.memberIconContainerBg};
  padding: 3px;
  box-sizing: border-box;
`;

const IconWrap = styled.div`
  display: flex;
`;

const Logo = styled.img`
  width: ${({ width }) => (width ? width : "30px")};
  height: ${({ height }) => (height ? height : "30px")};
  opacity: 1;
`;

const PromptHeader = styled.h2`
  margin: 15px 0;
`;

const instructionHeight = "0px";
const PromptText = styled.div`
  text-align: left;
  margin-bottom: 10px;
  padding-bottom: ${instructionHeight};
`;

const PromptTextDanger = styled.span`
  font-weight: bold;
  color: ${colors.danger};
`;

const InstructionWrapper = styled.div`
  height: ${instructionHeight};
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius-bottom-left: 10px;
  border-radius-bottom-right: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

const androidInstructionHeight = "100px";
const AndroidPromptText = styled.div`
  text-align: left;
  margin-bottom: 10px;
  padding-bottom: ${androidInstructionHeight};
`;

const AndroidInstructionWrapper = styled.div`
  height: ${androidInstructionHeight};
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius-bottom-left: 10px;
  border-radius-bottom-right: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

const PromptInstruction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
`;

const PromptInstructionText = styled.span`
  padding: 0 8px;
  color: black;
  padding: 1px 5px;
  display: flex;
`;

const MacPromptCloser = styled(MdClose)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const BoldUnderline = styled.span`
  font-weight: bold;
  text-decoration: underline;
`;

const AlertHeader = styled.h2`
  color: ${colors.secondaryOffset};
  margin: 0 auto;
  padding: 5px 0;
  border-bottom: 1px solid ${colors.secondaryOffset};
  width: 100%;
  text-align: center;
`;

const ProfileHeader = styled.h2`
  color: ${colors.secondaryOffset};
  margin: 0 auto;
`;

const RelativeWrapper = styled.div`
  position: relative;
  display: flex;
`;

const ProfileDropdown = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  position: absolute;
  top: 120%;
  top: calc(100% + 20px);
  right: 5%;
  max-width: 60vw;
  width: 200px;
  height: 175px;
  border-radius: 5px;
  border: 2px solid ${colors.secondary};
  background-color: ${colors.secondary};
  z-index: 11;

  &:after {
    content: "";
    position: absolute;
    top: -20px;
    right: 10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid ${colors.secondary};
  }
`;

const ProfileDropdownBtn = styled.button`
  background-color: ${colors.primary};
  color: white;
  height: 35px;
  width: 90%;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  margin: 5px auto;
`;

const AlertDropdownWrapper = styled.div`
  margin-top: 63px;
  position: absolute;
  width: 100vw;
  left: 0;
`;

const AlertDropdown = styled.div`
  display: block;
  min-height: 30vh;
  position: absolute;
  width: 95vw;
  left: 2.5vw;
  border-radius: 5px;
  border: 2px solid ${colors.secondary};
  background-color: ${colors.secondary};
  box-sizing: border-box;
  z-index: 11;
  max-height: 70vh;
  overflow-y: scroll;
`;

const MenuSelect = styled(StyledSelect)`
  width: 100%;
  margin: 0;
  min-width: unset;
  max-width: unset;
  color: black;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 90%;
  margin: 15px auto;
  color: ${colors.secondaryOffset};
`;

const LabelText = styled.span`
  font-weight: 600;
  font-size: 15px;
`;

const VertBtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80px;
  margin-bottom: 10px;
`;

const Go = styled.button`
  ${ButtonLinkStyles}
  width: 90%;
  font-size: 18px;
  margin: 0 auto;
`;

const MenuHeader = styled.h2`
  font-size: 20px;
  text-align: center;
  color: ${colors.secondaryOffset};
  margin: 10px 0 0 0;
`;

const getActiveByPath = () => {
  const { pathname } = window.location;
  if (pathname === "/member/profile") {
    return "profile";
  }
  const splitPath = pathname.trim().split("/");
  if (splitPath.length > 2 && splitPath[1] === "member" && splitPath[2] === "venue") {
    return "venue";
  }
  return "home";
};

const cookieName = "install_prompt";
export const MemberLanding = () => {
  let { pathname } = useLocation();
  let { path } = useRouteMatch();

  const { loading, error, data } = useQuery(GET_USER_FROM_COOKIE, {
    variables: { cookie: getCookie("uuid") },
    fetchPolicy: "no-cache"
  });

  const [isMacPromptVisible, setMacPrompt] = useState(false);
  const [navMenuWidth, setNavMenuWidth] = useState("0");
  const [mapMenuWidth, setMapMenuWidth] = useState("0");
  const [navMenuHeight, setNavMenuHeight] = useState("0");
  const [active, setActive] = useState(getActiveByPath());
  const [isProfileDropdownOpen, setProfileDropdown] = useState(false);
  const [isSearchOpen, setSearch] = useState(false);
  const [isMapOpen, setMapOpen] = useState(false);
  const [isAlertOpen, setAlert] = useState(false);
  const [searchData] = useSearchContext();
  const [venueNavData, venueNavDispatch] = useVenueNavContext();
  const venueDropdown = useQuery(GET_VENUE_DROPDOWN, {
    variables: { cookie: getCookie("uuid") },
    fetchPolicy: "no-cache"
  });

  const settingsQuery = useQuery(GET_SETTINGS, {
    fetchPolicy: "no-cache"
  });
  const [notifications, setNotifications] = useState([]);

  const toggleMapOn = useCallback(() => {
    setMapMenuWidth("100%");
    toggleVertMenuOff();
    setProfileDropdown(false);
    setSearch(false);
    toggleMenuOff();
    setAlert(false);
    setMapOpen(true);
  }, [setMapMenuWidth]);

  const toggleMapOff = useCallback(() => {
    setMapMenuWidth("0");
    setMapOpen(false);
  }, [setMapMenuWidth]);

  // const bodyClick = useCallback(() => {
  //   setProfileDropdown(false);
  // }, [setProfileDropdown]);

  // useEffect(() => {
  //   document.body.removeEventListener("click",);
  //   document.body.addEventListener("click", bodyClick);
  // }, [bodyClick]);

  useEffect(() => {
    const splitPath = pathname.trim().split("/");
    if (pathname === "/member/profile") {
      setActive("profile");
    } else if (splitPath.length > 2 && splitPath[1] === "member" && splitPath[2] === "venue") {
      setActive("venue");
    } else {
      setActive("home");
    }
  }, [pathname, setActive]);

  useEffect(() => {
    if (settingsQuery.data) {
      const notificationStrings = settingsQuery.data.settings.edges.filter(
        (setting) => setting.node.group === "notifications"
      );
      setNotifications(
        notificationStrings.map((element) => {
          const { node } = element;
          const data = JSON.parse(node.data);
          const retObj = {
            ...data,
            settingsId: node.settingsId,
            group: node.group
          };
          return retObj;
        })
      );
    }
  }, [settingsQuery.data]);

  const toggleMenuOn = useCallback(() => {
    setNavMenuWidth("100%");
  }, [setNavMenuWidth]);

  const toggleMenuOff = useCallback(() => {
    setNavMenuWidth("0");
  }, [setNavMenuWidth]);

  const toggleVertMenuOn = useCallback(() => {
    setNavMenuHeight("295px");
  }, [setNavMenuWidth]);

  const toggleVertMenuOff = useCallback(() => {
    setNavMenuHeight("0");
  }, [setNavMenuWidth]);

  const togglePromptOff = useCallback(() => {
    const d = new Date();
    // 1 wk
    const expires = new Date(d.getTime() + 604800000);
    document.cookie = `${cookieName}=closed;expires=${expires.toUTCString()};path=/`;
    setMacPrompt(false);
  }, [setMacPrompt]);

  useEffect(() => {
    if (getCookie(cookieName) === "closed") {
      setMacPrompt(false);
      return;
    }
    let showPrompt = ["iPhone", "iPad", "iPod"].includes(navigator.platform);
    if (showPrompt) {
      const standalone = window.navigator.standalone;
      if (standalone === true || standalone === undefined) {
        showPrompt = false;
      }
    }
    setMacPrompt(false /*showPrompt*/);
  }, [setMacPrompt]);

  const hideProfileDropdown = useCallback(() => {
    setProfileDropdown(false);
  }, [setProfileDropdown]);

  const handleHome = useCallback(() => {
    setActive("home");
    toggleMapOff();
    hideProfileDropdown();
    setSearch(false);
    toggleMenuOff();
    setAlert(false);
    toggleVertMenuOff();
  }, [setActive, hideProfileDropdown, toggleMenuOff, toggleMapOff, setSearch]);

  // const handleSearch = useCallback(() => {
  //   setActive("search");
  //   hideProfileDropdown();
  //   toggleMenuOn();
  // }, [setActive]);

  const showSearch = useCallback(() => {
    hideProfileDropdown();
    toggleVertMenuOff();
    setSearch(true);
    setAlert(false);
    toggleMenuOn();
    toggleMapOff();
  }, [setSearch, hideProfileDropdown, toggleMenuOff, setSearch, toggleMapOff]);

  const hideSearch = useCallback(() => {
    setSearch(false);
    toggleMenuOff();
    toggleMapOff();
  }, [setSearch, toggleMenuOff, toggleMapOff]);

  const completeSearch = useCallback(() => {
    setSearch(false);
    toggleMenuOff();
    toggleMapOff();
    setActive("home");
  }, [toggleMenuOff, setSearch, setActive, toggleMapOff]);

  const showAlert = useCallback(() => {
    setAlert(true);
    setSearch(false);
    toggleMenuOff();
    toggleVertMenuOff();
    hideProfileDropdown();
    toggleMapOff();
  }, [setSearch, hideProfileDropdown, setAlert, toggleMenuOff, toggleMapOff]);

  const hideAlert = useCallback(() => {
    setAlert(false);
  }, [setSearch]);

  const handleProfile = useCallback(() => {
    setActive("profile");
    setSearch(false);
    toggleMenuOff();
    toggleMapOff();
  }, [setActive, setSearch, toggleMenuOff, toggleMapOff]);

  const showProfileDropdown = useCallback(() => {
    setProfileDropdown(true);
    setSearch(false);
    setAlert(false);
    toggleVertMenuOff();
    toggleMenuOff();
    toggleMapOff();
  }, [setActive, setProfileDropdown, toggleMenuOff, toggleMapOff]);

  const handleVenue = useCallback(() => {
    setProfileDropdown(false);
    setSearch(false);
    toggleMenuOff();
    toggleVertMenuOn();
    setAlert(false);
    toggleMapOff();
  }, [setProfileDropdown, setSearch, toggleVertMenuOn, toggleMapOff]);

  const handleViewVenue = useCallback(() => {
    toggleVertMenuOff();
    setActive("venue");
  }, [setActive, toggleVertMenuOff]);

  const logout = useCallback(() => {
    handleLogout();
  }, []);

  const handleSelect = useCallback((value) => {
    if (value === null) {
      value = [];
    }
    venueNavDispatch({
      type: "SET_VENUE",
      payload: value.value
    });
  });

  const handleViewOffersFromMap = useCallback(() => {
    toggleMapOff();
  }, [toggleMapOff]);

  const handleRefresh = useCallback(() => {
    window.location.reload();
    return Promise.resolve();
  });

  if (error) {
    Object.keys(error).map((key) => console.warn(key, error[key]));

    return (
      <div>
        {error.message}
        <br />
        <a href="/">login here</a>
      </div>
    );
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <PullToRefresh onRefresh={handleRefresh} pullDownThreshold={100}>
      <ContentWrapper>
        <HeaderPlaceholder></HeaderPlaceholder>
        <Header>
          <NavMenu width={navMenuWidth}>
            <MenuHeader>Search Options</MenuHeader>
            <VenueSearchMenu onSearch={completeSearch} />
          </NavMenu>
          <MapWrapper open={isMapOpen}>
            {venueDropdown?.data && (
              <Map
                open={isMapOpen}
                venues={venueDropdown.data.allActiveVenues}
                onViewOffers={handleViewOffersFromMap}
              />
            )}
          </MapWrapper>
          {/* <NavMenuVertical height={navMenuHeight}>
          <MenuHeader>Find Course</MenuHeader>
          <Label>
            <LabelText>Course</LabelText>
            <MenuSelect
              name="venueName"
              options={
                venueDropdown.data &&
                venueDropdown.data?.allActiveVenues
                  ?.sort((a, b) => {
                    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                    return 0;
                  })
                  .map((venue) => ({
                    value: venue.venueId,
                    label: venue.name
                  }))
              }
              isSearchable
              value={venueNavData.venueName}
              onChange={handleSelect}
              isClearable
              placeholder="<None>"
              maxMenuHeight={160}
            />
          </Label>
          <VertBtnWrapper>
            {// <Go onClick={toggleVertMenuOff}>Cancel</Go>
            }
            <Link
              to={`/member/venue${`/${venueNavData.venue}`}`}
              onClick={handleViewVenue}
              style={{ textDecoration: "none" }}
            >
              <Go>Go</Go>
            </Link>
          </VertBtnWrapper>
        </NavMenuVertical> */}
          {/* <Logo
          height="40px"
          width="40px"
          style={{ marginLeft: "10px" }}
          src={brewSaverLogo}
          alt="green saver logo"
        /> */}
          <IconContainer>
            <Link style={{ display: "flex" }} to="/member" onClick={handleHome}>
              <Home isActive={active === "home"} size="45px" />
            </Link>
            <IconWrap>
              <Search
                onClick={isSearchOpen ? hideSearch : showSearch}
                isActive={isSearchOpen || active === "search"}
                size="45px"
              />
            </IconWrap>
            {/* <IconWrap>
            <VenueIcon
              onClick={navMenuHeight === "0" ? handleVenue : toggleVertMenuOff}
              isActive={active === "venue" || navMenuHeight !== "0"}
              size="45px"
            />
          </IconWrap> */}
            <IconWrap>
              <MapIcon onClick={isMapOpen ? toggleMapOff : toggleMapOn} isActive={isMapOpen} size="45px" />
            </IconWrap>
            <IconWrap>
              <Alert
                onClick={isAlertOpen ? hideAlert : showAlert}
                isActive={isAlertOpen || active === "alert"}
                size="45px"
              />
              {isAlertOpen && (
                <AlertDropdownWrapper>
                  <AlertDropdown>
                    <AlertHeader>Notifications</AlertHeader>{" "}
                    {notifications.map((notification) => {
                      return (
                        <AlertWidget
                          title={notification.header}
                          content={notification.body}
                          isActive={false}
                        />
                      );
                    })}
                    <AlertWidget
                      title="iPhone - Save to Home Screen"
                      content={
                        <>
                          <PromptText>
                            Using your mobile browser, go to https://www.greensavermobile.com and look for the
                            icon circled in the image below. Click that, then scroll down to "Add to Home
                            Screen". Click that and then "Done".
                          </PromptText>
                          <img style={{ height: "auto", width: "100%" }} src={iosHomeScreen} />
                        </>
                      }
                      isActive={false}
                    />
                    <AlertWidget
                      title="Android - Save to Home Screen"
                      content={
                        <>
                          <AndroidPromptText>
                            Tap the prompt shown below when you are on the login page of Green Saver. To go
                            back to the login page{" "}
                            <Link style={{ color: colors.linkColor }} to="/" onClick={logout}>
                              you must logout
                            </Link>
                            .
                            <img
                              style={{ height: "auto", width: "100%", marginTop: "15px" }}
                              src={installAndroidImg}
                            />
                          </AndroidPromptText>
                          <AndroidInstructionWrapper>
                            <PromptInstructionText style={{ padding: "10px" }}>
                              <span>
                                If you are <PromptTextDanger>not seeing the prompt</PromptTextDanger> try
                                clearing your browsers history, close the browser tab and revisit Green Saver.
                              </span>
                            </PromptInstructionText>
                          </AndroidInstructionWrapper>
                        </>
                      }
                      isActive={false}
                    />
                  </AlertDropdown>
                </AlertDropdownWrapper>
              )}
            </IconWrap>
            <RelativeWrapper onClick={isProfileDropdownOpen ? hideProfileDropdown : showProfileDropdown}>
              <Profile isActive={isProfileDropdownOpen || active === "profile"} size="45px" />
              {isProfileDropdownOpen && (
                <ProfileDropdown>
                  <ProfileHeader>Welcome!</ProfileHeader>
                  <BtnContainer>
                    <Link
                      style={{ display: "flex", textDecoration: "none" }}
                      to="/member/profile"
                      onClick={handleProfile}
                    >
                      <ProfileDropdownBtn style={{ margin: "5px auto" }}>Profile</ProfileDropdownBtn>
                    </Link>
                    <Link style={{ display: "flex", textDecoration: "none" }} to="/member/scorecard">
                      <ProfileDropdownBtn style={{ margin: "5px auto" }}>Scorecard (beta)</ProfileDropdownBtn>
                    </Link>
                    <Link style={{ display: "flex", textDecoration: "none" }} to="/" onClick={logout}>
                      <ProfileDropdownBtn style={{ margin: "5px auto" }}>Logout</ProfileDropdownBtn>
                    </Link>
                  </BtnContainer>
                </ProfileDropdown>
              )}
            </RelativeWrapper>
          </IconContainer>
        </Header>
        {/* <Nav>
        <HomeButton to={`${url}`}>Home</HomeButton>
        <StyledLink to={`${url}/profile`}>Profile</StyledLink>
        <StyledLink onClick={handleLogout} to="/">
          Logout
        </StyledLink>
      </Nav> */}
        <Switch>
          <Route path={`${path}/profile`} component={MemberProfile} />
          <Route path={`${path}/scorecard`} component={MemberScorecard} />
          <Route path={`${path}/offer`} component={MemberOffer} />
          <Route path={`${path}/venue/:venueId`} component={MemberVenue} />
          <Route default component={MemberHome} />
        </Switch>
        {isMacPromptVisible && (
          <ModalWrapper>
            <Modal style={{ backgroundColor: colors.secondary, color: colors.secondaryOffset }}>
              <MacPromptCloser onClick={togglePromptOff} size={"30px"} />
              <Logo src={brewSaverLogo} alt="green saver logo" height="75px" width="75px" />
              <PromptHeader>Green Saver App {window.navigator.standalone}</PromptHeader>
              <PromptText>
                Install this application <BoldUnderline>using Safari</BoldUnderline> for quick and easy access
                from your home screen when you're ready to explore Colorado's best venues!{" "}
                {window.navigator.standalone}
              </PromptText>
              <InstructionWrapper>
                <PromptInstruction>
                  <PromptInstructionText>Just tap</PromptInstructionText>
                  <Logo
                    src={appleShareIcon}
                    alt="share"
                    height="25px"
                    width="auto"
                    style={{ color: colors.linkColor }}
                  />
                  <PromptInstructionText>then 'Add to Home Screen'</PromptInstructionText>
                </PromptInstruction>
                <PromptInstructionText>
                  You might need to <span style={{ color: colors.linkColor }}>Edit Actions...</span>
                </PromptInstructionText>
              </InstructionWrapper>
              {/* <ConfirmButtonWrapper>
              <Button onClick={handleDelete}>Yes, Delete</Button>
              <Button onClick={hideModal}>Cancel</Button>
            </ConfirmButtonWrapper> */}
            </Modal>
          </ModalWrapper>
        )}
        <Copyright color="white" />
      </ContentWrapper>
    </PullToRefresh>
  );
};
export default MemberLanding;
